<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="公告标题">
                <a-input v-model="queryParam.noticeTitle" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="操作人员">
                <a-input v-model="queryParam.createBy" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="公告类型">
                  <a-select placeholder="请选择" v-model="queryParam.noticeType" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="handleAdd()" v-hasPermi="['super:system:notice:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="handleUpdate(undefined, ids)"
            v-hasPermi="['super:system:notice:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button type="primary" :disabled="multiple" @click="exportHtmlMulti()">
            <a-icon type="diff" />导出文章
          </a-button>
          <a-button type="primary" v-if="queryParam.noticeType === '3'" @click="exportHtmlAll('3')"><a-icon type="diff" />一键导出咨询文章 </a-button>
          <a-button type="primary" v-if="queryParam.noticeType === '4'" @click="exportHtmlAll('4')"><a-icon type="diff" />一键导出案例文章 </a-button>
          <a-button type="primary" @click="exportMenusHtml()"><a-icon type="diff" />导出资讯页 </a-button>
          <a-button type="primary" @click="exportCaseMenusHtml()"><a-icon type="diff" />导出案例页 </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['super:system:notice:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="noticeId"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="noticeType" slot-scope="text, record" :title="typeFormat(record)">
          {{ typeFormat(record) }}
        </span>
        <span slot="status" slot-scope="text, record" :title="statusFormat(record)">
          {{ statusFormat(record) }}
        </span>
        <span slot="createTime" slot-scope="text, record" :title="parseTime(record.createTime)">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record, undefined)" v-hasPermi="['super:system:notice:edit']">
            <a-icon type="edit" />修改
          </a>
          <a @click="exportHtmlByType(record)"> <a-icon type="diff" />导出文章</a>
          <a-divider type="vertical" v-hasPermi="['super:system:notice:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['super:system:notice:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listNotice, delNotice } from '@/api/system/notice'
import { marked } from 'marked'
import { getNewsTemp, getMenusTemp, getCaseMenusTemp, getCaseNewsTemp } from './template/index'
// , getCaseMenusTemp, getCaseNewsTemp
export default {
  name: 'Notice',
  components: {},
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      articelTotal: 0,
      // 状态数据字典
      statusOptions: [],
      typeOptions: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        createBy: undefined,
        status: undefined,
        noticeType: undefined
      },
      columns: [
        {
          title: '公告标题',
          dataIndex: 'noticeTitle',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公告类型',
          dataIndex: 'noticeType',
          scopedSlots: { customRender: 'noticeType' },
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '操作人员',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('sys_notice_status').then((response) => {
      this.statusOptions = response.data
    })
    this.getDicts('sys_notice_type').then((response) => {
      this.typeOptions = response.data
    })
    this.queryParam.noticeType = this.$route.query.noticeType || undefined
    if (this.queryParam.noticeType) {
      setTimeout(() => {
      const url = window.location.href
      const valiable = url.split('?')[0]
      window.history.pushState(null, null, valiable)
    }, 100)
    }
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    /** 查询公告列表 */
    getList() {
      this.loading = true
      listNotice(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
        if (this.queryParam.noticeType === '3') {
          if (response.rows && response.rows.length) {
          this.articelSort = response.rows[0].sort
         }
        } else if (this.queryParam.noticeType === '4') {
          if (response.rows && response.rows.length) {
          this.caseSort = response.rows[0].sort
         }
        } else {
          this.getArticelSort()
        }
      })
    },
    // 公告状态字典翻译
    statusFormat(row) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    // 公告类型字典翻译
    typeFormat(row) {
      return this.selectDictLabel(this.typeOptions, row.noticeType)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        createBy: undefined,
        status: undefined,
        noticeType: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.noticeId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const noticeIds = row.noticeId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + noticeIds + '的数据',
        onOk() {
          return delNotice(noticeIds).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({
        name: 'NoticeForm',
        params: {
          id: undefined,
          formTitle: '添加公告',
          articelSort: this.articelSort,
          caseSort: this.caseSort
        }
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      const noticeId = row ? row.noticeId : ids
      this.$router.push({
        name: 'NoticeForm',
        params: {
          id: noticeId,
          formTitle: '修改公告',
          coverImage:
            row.coverImage.indexOf('https://') > -1
              ? row.coverImage
              : 'https://ywkj-cloud.oss-cn-shanghai.aliyuncs.com/picture/' + row.coverImage
        }
      })
    },
    getHTML(item, type) {
      const hc = marked(item.noticeContent)
      const newsTemp = type === '3' ? getNewsTemp(item) : getCaseNewsTemp(item)
      return newsTemp.header + hc + (newsTemp.source || '') + newsTemp.footer
    },

    exportNewsHtml(item) {
      const htmlContent = this.getHTML(item, '3')
      this.export2Html(htmlContent, 'news_article_' + item.noticeId)
    },
    exportCaseNewsHtml(item) {
      const htmlContent = this.getHTML(item, '4')
      this.export2Html(htmlContent, 'case_' + item.noticeId)
    },
    exportMenusHtml() {
      listNotice({
        noticeType: '3'
      }).then((response) => {
        const htmlContent = getMenusTemp(response.rows)
        this.export2Html(htmlContent, 'news')
      })
    },
    exportCaseMenusHtml() {
      listNotice({
        noticeType: '4'
      }).then((response) => {
        const htmlContent = getCaseMenusTemp(response.rows)
        this.export2Html(htmlContent, 'cases')
      })
    },
    export2Html(htmlContent, fileName) {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(new Blob([htmlContent], { type: '' }))
      a.href = url
      a.download = fileName + '.html'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    exportHtmlMulti() {
      for (const item of this.selectedRows) {
          this.exportHtmlByType(item)
      }
    },
    exportHtmlAll(type) {
      listNotice({
        noticeType: type
      }).then((response) => {
        for (const item of response.rows) {
          if (item.status === '0') {
          this.exportHtmlByType(item)
          }
        }
      })
    },
    exportHtmlByType(item) {
      if (item.noticeType === '3') {
          this.exportNewsHtml(item)
        } else if (item.noticeType === '4') {
          this.exportCaseNewsHtml(item)
        }
    },
    getArticelSort() {
      listNotice({
        pageNum: 1,
        pageSize: 1,
        noticeType: '3'
      }).then((response) => {
        if (response.rows && response.rows.length) {
          this.articelSort = response.rows[0].sort
        }
      })
      listNotice({
        pageNum: 1,
        pageSize: 1,
        noticeType: '4'
      }).then((response) => {
        if (response.rows && response.rows.length) {
          this.caseSort = response.rows[0].sort
        }
      })
    }
  }
}
</script>
